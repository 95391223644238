@use '../../../scss/mixins' as *;
@use '../../../scss/colors' as *;

.Card {
  width: 288px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @include onDesktop {
    position: relative;
  }

  .innerCard {
    width: 100%;
    display: flex;
    min-height: 158px;
    border: 1px dashed #dddddd;
    border-radius: 15px;
    background-color: #ffffff;
    // border-radius: 35px;
    // background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjg4IiBoZWlnaHQ9IjE1OCIgdmlld0JveD0iMCAwIDI4OCAxNTgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxyZWN0IHg9IjAuNSIgeT0iMC41IiB3aWR0aD0iMjg3IiBoZWlnaHQ9IjE1NyIgcng9IjM0LjUiIHN0cm9rZT0iIzRGNEU0RSIgc3Ryb2tlLWRhc2hhcnJheT0iMTUgMTUiLz4KPC9zdmc+Cg==");
    // background-repeat: no-repeat;

    @include onDesktop {
      min-height: 216px;
      // background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzk1IiBoZWlnaHQ9IjIxNyIgdmlld0JveD0iMCAwIDQwNCAyMTciIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxyZWN0IHg9IjAuMzcwMDk5IiB5PSIxLjI0MzQ1IiB3aWR0aD0iMzk1IiBoZWlnaHQ9IjIxNC40NyIgcng9IjU5LjgyNTkiIHN0cm9rZT0iIzRGNEU0RSIgc3Ryb2tlLXdpZHRoPSIwLjc1MjUyNyIgc3Ryb2tlLWRhc2hhcnJheT0iMTYuNTYgMTYuNTYiLz4KPC9zdmc+Cg==");
    }

    &.filled {
      border: none;
      background: linear-gradient(108deg, $color-primary 8.32%, rgba(159, 161, 172, 0.77) 22.38%, rgba(92, 95, 112, 0.54) 61.47%, $color-primary 94.63%, rgba(217, 217, 217, 0.00) 124.01%);
    }

    &:has(>.filling) {
      border-bottom: none;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    .empty {
      display: flex;
      flex-grow: 1;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      gap: 12px;
      cursor: pointer;

      img {
        width: 32px;
        height: 32px;
      }

      span {
        color: #4F4E4E;
        font-size: 15px;
        letter-spacing: 0.3px;
        text-transform: uppercase;
      }
    }

    .filling {
      width: 100%;
      padding: 32px 0 24px 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 36px;

      @include onDesktop {
        justify-content: center;
        gap: 60px;
      }

      .cardNumberElement {
        width: 50%;

        @include onDesktop {
          width: 150px;
        }
      }

      .cvcAndExpiresElement {
        width: 60px;
      }

      .top {
        width: 100%;
        display: flex;
        justify-content: center;

        @include onDesktop {
          img {
            transform: scale(1.5);
          }
        }
      }

      .bottom {
        width: 100%;
        padding: 0 24px;
        display: flex;
        justify-content: space-between;

        @include onDesktop {
          padding: 0 60px;
        }
      }
    }

    .filled {
      width: 100%;
      display: flex;
      gap: 25px;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .cardHolderName {
        color: $color-accent;
        font-size: 28px;
        text-transform: uppercase;
        margin: 0;
      }

      .dotsGroup {
        display: flex;
        gap: 3px;

        .dot {
          height: 8px;
          width: 8px;
          border-radius: 50%;
          background: #f6f6f6;
        }
      }

      .cardNumber {
        margin: 0;
        display: flex;
        align-items: center;
        gap: 12px;
        color: #FFF;
        font-size: 15px;
        letter-spacing: 0.525px;

        @include onDesktop {
          img {
            padding-left: 12px;
            transform: scale(1.25);
          }
        }
      }

      .cardDetailBottomContainer {
        width: 50%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .cardDetailInfo {
          display: flex;
          gap: 8px;
          align-items: center;
          color: $color-accent;
          font-size: 14px;

          .label {
            font-weight: bold;
          }
        }
      }
    }

    .loading {
      display: flex;
      flex-grow: 1;
      justify-content: center;
      align-items: center;
    }
  }

  .address {
    width: 100%;
    padding: 20px 20px 28px;
    border: 1px dashed #dddddd;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    border-top: 1px solid #dddddd;
    background-color: #ffffff;

    & > h1 {
      font-size: 22px;
      text-align: center;
      margin-top: 0;
    }
  }

  .updateButtonContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    margin-top: 20px;
  }

  .button {
    width: 50%;
    height: 40px;
    font-size: 14px;
    padding-right: 25px;

    @include onDesktop {
      width: 170px;
      text-align: left;
      height: 60px;
      font-size: unset;
      padding-right: unset;
      padding-left: 20px;
    }
  }

  .cancelButton {
    padding-right: 0;
    padding-left: 0;
    text-align: center;
  }
}