@use '../../../../scss/mixins' as *;

.PublicProfileLinkList {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 6px;
  list-style: none;

  @include onDesktop {
    gap: 20px;
  }

  .noLinksFound {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    background-color: #fff;
    font-style: italic;
    color: #555;
    padding: 15px 15px 15px 20px;
    border-radius: 50px;
  }
}
