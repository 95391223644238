@use '../../../../scss/mixins' as *;

.Customer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 6px;

  @include onDesktop {
    gap: 12px;

    &.serviceProvider {
      flex-direction: row;
      align-items: center;
      gap: 16px;

      .avatarSpView {
        height: 44px;
        max-width: 44px;
        min-width: 44px;
      }
    }
  }

  &.noChargeExists {
    .avatar {
      border: 2px solid red;
    }
  }

  .title {
    color: #161616;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    letter-spacing: 0.26px;

    @include onDesktop {
      font-size: 15px;
      letter-spacing: 0.5px;
    }
  }

  .infoContainer {
    display: flex;
    align-items: center;

    .infoInnerContainer {
      @include onDesktop {
        display: flex;
        align-items: center;
        gap: 25px;
      }

      .name {
        display: flex;
        align-items: center;
        gap: 8px;
        color: #161616;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.16px;

        @include onDesktop {
          color: #161616;
          font-size: 14px;
          letter-spacing: 0.32px;
        }

        .icon {
          width: 16px;
          height: 16px;
          background: #F3F2F2;

          @include onDesktop {
            width: 22px;
            height: 22px;
          }
        }
      }

      .email {
        display: none;

        @include onDesktop {
          display: inline;
          color: #161616;
          font-size: 14px;
          font-weight: 300;
          letter-spacing: 0.2px;
        }
      }
    }
  }
}
