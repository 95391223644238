@use '../../scss/mixins' as *;

.Table {
  position: relative;
  margin: 0;
  font-size: 13px;
  border-collapse: collapse;

  .empty {
    height: 100px;
    td {
      text-align: center;
    }
  }

  tbody {
    border-top: 2px solid #e3e3e3;

    tr:nth-child(even) {
      background-color: #FFF;
    }
    tr:nth-child(odd) {
      background-color: #F2F2F2;
    }
  }

  tr {
    td:first-child {
      padding-left: 15px;
    }

    td:last-child {
      padding-right: 15px;
    }
  }

  tr:first-child {
    th:first-child {
      border-top-left-radius: 24px;
      padding-left: 15px;
    }

    th:last-child {
      border-top-right-radius: 24px;
      padding-right: 15px;
    }
  }

  tr:last-child {
    td:first-child {
      border-bottom-left-radius: 24px;
    }

    td:last-child {
      border-bottom-right-radius: 24px;
    }
  }
}
