@use '../../scss/mixins' as *;
@use '../../scss/colors' as *;

.dialog {
  max-width: 325px;
  max-height: 152px;
  width: 100%;
  position: fixed;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  z-index: 100;

  @include onDesktop {
    max-width: 440px;
    max-height: 206px;
    right: calc(50% - 300px / 2);
  }
}

.DeleteModal {
  padding: 20px 16px;
  position: relative;
  border: 1px solid $color-primary;
  border-radius: 25px;
  background: #F8F8F8;

  @include onDesktop {
    padding: 26px 20px;
    border-radius: 45px;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;

    .title {
      margin: 0 0 38px;
      text-align: center;
      font-size: 18px;
      font-weight: 400;
      letter-spacing: 0.52px;
      color: $color-primary;

      @include onDesktop {
        margin: 0 0 70px;
        font-size: 20px;
      }

      .username {
        font-weight: 500;
      }
    }

    .buttons {
      width: 100%;
      display: flex;
      gap: 12px;
    }
  }
}
