@use '../../scss/mixins' as *;
@use '../../scss/colors' as *;

.AccountSettingsPage {
  min-height: 100vh;

  .content {
    min-height: calc(100vh - 90px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;

    @include onDesktop {
      min-height: calc(100vh - 120px);
      padding-top: 80px;
      gap: 40px;
    }

    .top {
      @include onDesktop {
        display: flex;
        justify-content: center;
      }

      .title {
        margin: 0 0 20px 0;
        color: $color-primary;
        font-size: 25px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 1px;

        @include onDesktop {
          display: none;
        }
      }

      .form {
        padding: 20px 16px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 30px;
        border-radius: 25px;
        background: $color-accent;

        @include onDesktop {
          max-width: 880px;
          width: 100%;
          padding: 0;
          flex-direction: row;
          align-items: flex-start;
          gap: 84px;
          background: transparent;
        }

        .avatarContainer {
          @include onDesktop {
            display: flex;
            flex-direction: column;
            gap: 20px;
          }

          .logout {
            display: none;

            @include onDesktop {
              display: flex;
              justify-content: center;
              align-items: center;
              gap: 8px;
              color: #606161;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              letter-spacing: 1px;
              text-decoration-line: underline;
              cursor: pointer;
            }
          }
        }

        .formGroup {
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 16px;

          @include onDesktop {
            width: unset;
            flex-grow: 1;
          }

          .inputContainer {
            position: relative;

            .inputTitle {
              position: absolute;
              top: -4px;
              left: 20px;
              color: #161616;
              font-size: 14px;
              font-weight: 300;
              letter-spacing: 0.2px;

              @include onDesktop {
                top: -8px;
                left: 28px;
                font-size: 16px;
                letter-spacing: 0.25px;
              }
            }

            .input {
              width: 100%;
              background: $color-background;

              @include onDesktop {
                background: $color-accent;
              }
            }

            &.rate {
              &::after {
                content: '$ / hour';
                position: absolute;
                right: 15px;
                top: 50%;
                transform: translateY(-50%);
                color: #161616;
                font-size: 14px;
                font-weight: 300;
                letter-spacing: 0.26px;

                @include onDesktop {
                  right: 30px;
                  font-size: 16px;
                  font-weight: 400;
                  letter-spacing: 0.4px;
                }
              }

              .input {
                padding-right: 100px;
              }
            }
          }

          .changePassword {
            display: none;

            @include onDesktop {
              margin-bottom: 40px;
              display: block;
            }
          }

          .buttonsContainer {
            display: none;

            @include onDesktop {
              display: flex;
              gap: 24px;
            }
          }
        }
      }
    }

    .bottom {
      display: flex;
      flex-direction: column;
      gap: 30px;

      .changePassword {
        @include onDesktop {
          display: none;
        }
      }

      .buttonsContainer {
        display: flex;
        gap: 15px;

        @include onDesktop {
          display: none;
        }

        .roundButton {
          min-width: 60px;
          height: 60px;
          background: $color-primary;
        }
      }

      .deleteButton {
        display: none;

        @include onDesktop {
          margin: 0 auto;
          padding: 0;
          display: flex;
          align-items: center;
          gap: 10px;
          border: none;
          color: #4F4E4E;
          font-size: 16px;
          font-weight: 600;
          text-decoration-line: underline;
          background: transparent;
          cursor: pointer;

          img {
            height: 18px;
            width: 18px;
          }
        }
      }
    }

    .changePassword {
      color: #4F4E4E;
      font-size: 15px;
      font-weight: 600;
      text-decoration-line: underline;
      text-align: center;
      cursor: pointer;

      @include onDesktop {
        font-size: 16px;
        text-align: right;
      }
    }
  }
}
