@use '../../../../../../scss/mixins' as *;

.TotalOrLoggedHours {
  display: flex;
  flex-direction: column;
  justify-content: center;

  .title {
    color: #161616;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.18px;

    @include onDesktop {
      letter-spacing: 0.3px;
    }
  }

  .content {
    display: flex;
    align-items: center;
    color: #161616;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    img {
      margin-right: 6px;

      @include onDesktop {
        height: 12px;
        width: 12px;
      }
    }

    @include onDesktop {
      font-weight: 500;
    }
  }
}