@use '../../../../scss/mixins' as *;
@use '../../../../scss/colors' as *;

.PrimaryButton {
  height: 60px;
  width: 100%;
  padding: 0;
  border: none;
  border-radius: 55px;
  background: $color-primary;
  color: #F6F6F6;
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;

  @include onDesktop {
    height: 60px;
    font-size: 20px;
  }

  &.outline {
    border: 1px solid $color-primary;
    background: transparent;
    color: $color-primary;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &.icon {
    position: relative;

    &::after {
      content: '';
      height: 30px;
      width: 30px;
      position: absolute;
      top: 50%;
      right: 30px;
      transform: translate(50%, -50%);
      border-radius: 50%;
      background: #F6F6F6;
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  &.iconArrow::after {
    background-image: url('../../../../assets/icons/arrow-down.svg');
  }

  &.iconLogout::after {
    background-image: url('../../../../assets/icons/logout.svg');
  }

  &.iconCheckmark::after {
    background-image: url('../../../../assets/icons/checkmark-blue.svg');
  }
}
