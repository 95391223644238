@use '../../../../../scss/mixins' as *;
@use '../../../../../scss/colors' as *;

.NumberCounter {
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;

  @include onDesktop {
    width: unset;
  }

  .counterBox {
    width: 90px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f8f8f8;
    font-size: 25px;
    font-weight: bold;
    border: 1px solid #999;

    @include onDesktop {
      width: 110px;
      height: 95px;
      font-size: 35px;
    }
  }

  .arrow {
    background-color: transparent;
    width: unset;
    height: unset;
    padding: 10px;
  }

  .upArrow {
    transform: scale(1.8);
  }

  .downArrow {
    padding-bottom: 5px;
    transform: scale(1.8) rotate(-180deg);
  }

  .counterLabel {
    font-weight: bolder;
  }

}