@use '../../../../scss/mixins' as *;
@use '../../../../scss/colors' as *;

.Title {
  .title {
    margin: 0;
    color: #161616;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.32px;
    word-break: break-word;

    @include onDesktop {
      font-size: 17px;
      letter-spacing: 0.5px;
    }
  }

  .link {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .inputContainer {
    width: 100%;
    margin-right: 20px;
    position: relative;

    @include onDesktop {
      margin-right: 90px;
    }

    .titleInput {
      height: 40px;
      width: 100%;
      padding: 8px;
      border-radius: 35px;
      font-size: 14px;
      letter-spacing: 0.2px;
      color: rgba(22, 22, 22, 0.79);
      background: #F6F6F6;

      @include onDesktop {
        height: 60px;
        padding: 12px 32px;
        font-size: 26px;
        font-weight: 500;
        letter-spacing: 0.7px;
      }
    }

    .titleButton {
      background: $color-primary;
      position: absolute;
      top: 0;
      right: 0;
    }
  }
}