@use '../../../../scss/mixins' as *;
@use '../../../../scss/colors' as *;

.DropdownList {
  max-height: calc(40px * 4 + 5px * 3 + 12px);
  width: 100%;
  padding: 4px 15px 8px;
  display: none;
  background: $color-background;
  border-radius: 0 0 20px 20px;
  z-index: 2;

  @include onDesktop {
    max-height: calc(60px * 4 + 5px * 3 + 36px);
    padding: 9px 15px 12px;
    border-radius: 0 0 40px 40px;
    background: $color-accent;
  }

  &.open {
    display: flex;
    flex-direction: column;
  }

  .items {
    margin: 0;
    padding: 0 8px 0 0;
    display: flex;
    flex-direction: column;
    gap: 5px;
    list-style: none;
    overflow-y: auto;
  }
}