@use '../../scss/mixins' as *;
@use '../../scss/colors' as *;

.HeaderMenuContainer {
  height: 100%;

  @include onDesktop {
    display: flex;
    width: calc(100% - 300px);
    margin: 0 auto;

    .container {
      flex-grow: 1;
    }
  }

  .menuButton {
    position: fixed;
    top: 20px;
    left: 30px;
    padding: 0;
    border: none;
    background: transparent;
    cursor: pointer;
    z-index: 1000;
  }

  .content {
    padding: 0 20px 25px;

    @include onDesktop {
      padding: 0 40px 32px;
    }
  }
}
