@use '../../../scss/mixins' as *;
@use '../../../scss/colors' as *;

.AddCustomerDropdown {
  &.error {
    .dropdownToggle {
      background: rgba(219, 40, 40, 0.14);
    }
  }

  &.open {
    .dropdownToggle {
      background: $color-background;
      border-radius: 16px 16px 0 0;

      @include onDesktop {
        background: $color-accent;
        border-radius: 36px 36px 0 0;
      }

      &::after {
        background: #F6F6F6;
        background-image: url('../../../assets/icons/arrow-blue.svg');
        background-repeat: no-repeat;
        background-position: center;

        @include onDesktop {
          background: transparent;
          background-image: url('../../../assets/icons/arrow-expand.svg');
          background-repeat: no-repeat;
          background-position: center;
          transform: rotate(270deg) scale(1.5);
        }
      }
    }
  }

  &.selected {
    .dropdownToggle {
      padding: 0;
    }
  }

  .dropdownToggle {
    height: 40px;
    width: 100%;
    padding: 12px 16px;
    display: flex;
    position: relative;
    border: none;
    border-radius: 20px;
    color: #161616;
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 0.26px;
    background: $color-background;
    overflow: hidden;
    cursor: pointer;

    @include onDesktop {
      height: 60px;
      padding: 18px 22px;
      display: flex;
      align-items: center;
      border-radius: 40px;
      font-size: 14px;
      font-weight: 400;
      color: rgba(22, 22, 22, 0.70);
      background: $color-accent;
    }

    &::after {
      content: '';
      height: 40px;
      width: 40px;
      position: absolute;
      top: 0;
      right: 0;
      background: $color-primary;
      border-radius: 50%;
      background-image: url('../../../assets/icons/arrow-white.svg');
      background-repeat: no-repeat;
      background-position: center;

      @include onDesktop {
        height: 60px;
        width: 60px;
        background: transparent;
        background-image: url('../../../assets/icons/arrow-expand.svg');
        background-repeat: no-repeat;
        background-position: center;
        transform: rotate(90deg) scale(1.5);
      }
    }
  }
}