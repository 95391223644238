@use '../../../scss/mixins' as *;

.EngagementsList {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 15px;
  list-style: none;

  @include onDesktop {
    gap: 30px;
  }
}

.noEngagementText {
  font-weight: bold;
  padding: 10px 10px;
  text-align: center;
  font-size: 15px;

  @include onDesktop {
    text-align: unset;
    padding: 0 10px;
  }
}
