@use '../../../scss/mixins' as *;

.Avatar {
  height: 40px;
  width: 40px;
  margin-right: 12px;
  border-radius: 50%;
  object-fit: cover;

  @include onDesktop {
    height: 60px;
    width: 60px;
    margin-right: 14px;
  }
}