@use '../../../scss/mixins' as *;
@use '../../../scss/colors' as *;

.ServiceProviderDetails {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;

  @include onDesktop {
    margin-bottom: 20px;
  }

  .inputContainer {
    width: 100%;
    display: flex;
    flex-direction: column;

    .input {
      border-radius: 20px;
      background: $color-background;
      color: #161616;
      font-size: 14px;
      font-weight: 300;
      letter-spacing: 0.26px;

      @include onDesktop {
        border-radius: 40px;
        background: $color-accent;
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 0.4px;
      }

      &.rate {
        padding-right: 100px;
      }
    }

    .rateContainer {
      display: flex;
      flex-direction: column;
      position: relative;

      &::after {
        content: '$ / hour';
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translateY(-50%);
        color: #161616;
        font-size: 14px;
        font-weight: 300;
        letter-spacing: 0.26px;

        @include onDesktop {
          right: 30px;
          font-size: 16px;
          font-weight: 400;
          letter-spacing: 0.4px;
        }
      }
    }
  }

  .buttonsContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 8px;

    @include onDesktop {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    .left, .right {
      display: flex;
      gap: 8px;
    }

    .profileButtons {
      height: 45px;
      font-size: 14px;

      @include onDesktop {
        width: 200px;
        font-size: 16px;
      }

      &::after {
        transform: translate(50%, -50%) scale(0.8);
      }
    }

    .updateProfileButton, .previewProfileButton {
      padding-right: 30px;

      @include onDesktop {
        padding-right: 25px;
      }
    }
  }

  .profileLinksContainer {
    display: flex;
    flex-direction: column;
  }
}