@use '../../scss/mixins' as *;
@use '../../scss/colors' as *;

.TopHeaderContainer {
  position: sticky;
  top: 0;
  z-index: 999;
  width: 100%;
  height: 60px;
  background-color: $color-primary;

  .content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    margin: 0 auto;
    padding: 0;

    @include onDesktop {
      max-width: calc(100% - 300px);
    }

    .left {
      margin-left: 40px;

      .logo {
        display: none;

        @include onDesktop {
          display: block;
          height: 54px;
          width: 180px;
        }
      }
    }

    .right {
      margin-right: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 0;
      text-align: center;
      gap: 15px;

      @include onDesktop {
        text-align: unset;
        margin-right: 44px;
        gap: 25px;
      }

      .messageBoxLink {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        .unreadMessageCount {
          position: absolute;
          top: -8px;
          right: -13px;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;
          padding: 3px 7px;
          font-size: 10px;
          border-radius: 50%;
          border: 2px solid $color-danger;
          background-color: $color-background;
          color: $color-danger;
        }
      }
    }


    .Link {
      text-decoration: none;
      color: $color-background;
      font-size: 12px;

      @include onDesktop {
        font-size: 14px;
      }

      &:hover {
        text-decoration: underline;
      }
    }

    .LinkButton {
      text-decoration: none;
      color: $color-background;
      border: 1px solid $color-background;
      padding: 5px 10px;
      font-size: 12px;

      @include onDesktop {
        padding: 5px 15px;
        font-size: 14px;
      }
    }
  }
}