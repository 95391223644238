@use '../../../scss/mixins' as *;
@use '../../../scss/colors' as *;

.Switch {
  display: flex;
  width: -moz-fit-content;
  width: fit-content;
  align-items: center;
  border-radius: 35px;
  background: $color-accent;

  @include onDesktop {
    width: max-content;
    border-radius: 28px;
  }

  .option {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    font-weight: 400;
    text-transform: uppercase;
    border-radius: 35px;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 10px 12px;

    @include onDesktop {
      height: 60px;
      padding-inline: 20px;
      padding: 15px;
      width: max-content;
      min-width: 150px;
      font-size: 14px;
      border-radius: 28px;
    }

    &.selected {
      background: $color-primary;
      color: $color-background;
      font-weight: 500;
    }
  }
}