@use '../../scss/mixins' as *;
@use '../../scss/colors' as *;

.DirtyPaymentLogsPage {
  min-height: 100vh;
  padding-bottom: 72px;

  @include onDesktop {
    padding-bottom: 0;
  }

  .listContainer {
    margin-bottom: 40px;
  }
}