@use '../../scss/mixins' as *;
@use '../../scss/colors' as *;

.contentContainer {
  margin-top: 30px;
  flex-direction: column;
  padding: 16px;
  border-radius: 25px;
  background-color: $color-accent;
  position: relative;

  @include onDesktop {
    padding: 30px;
  }

  p {
    margin: 0;
  }

  .placeholder {
    color: #555;
  }

  .editButton {
    width: 40px;
    height: 40px;
    position: absolute;
    right: 8px;
    top: 8px;
    border: 1px solid gray;
  }

  .saveButton {
    width: 130px;
    height: 45px;
    // width: fit-content;
    display: flex;
    padding-left: 20px;
    justify-content: flex-start;
    align-items: center;
    position: fixed;
    // right: 20px;
    bottom: 20px;
    border: 1px solid gray;
  }
}