@use '../../../scss/mixins' as *;
@use '../../../scss/colors' as *;

.PaymentHeader {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;
    justify-content: space-between;

    @include onDesktop {
        flex-direction: row;
    }

    .flexLeftContainer {
        display: flex;
        gap: 8px;
        width: 100%;
        justify-content: center;
        flex-wrap: wrap;

        @include onDesktop {
            width: unset;
            justify-content: unset;
            flex-wrap: unset;
        }
    }

    .flexRightContainer {
        display: flex;
        gap: 8px;
        width: 100%;
        justify-content: center;

        @include onDesktop {
            width: unset;
            justify-content: unset;
        }

    }

    .infoBox {
        display: flex;
        flex: 50%;
        flex-direction: column;
        justify-content: center;
        height: 70px;
        gap: 10px;
        font-weight: bold;
        border-radius: 15px;
        background-color: #ffffff;

        @include onDesktop {
            flex: unset;
            width: 120px;
            height: 80px;
        }

        .label {
            font-size: 14px;
            text-align: center;
            color: green;

            @include onDesktop {
                font-size: 14px;
            }
        }

        .value {
            font-size: 14px;
            text-align: center;
            @include onDesktop {
                font-size: 16px;
            }
        }
    }

    .totalEarningInfoBox {
        @include onDesktop {
            width: 180px;
        }
    }
}

.PaymentDetails {
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    padding: 20px;
    background-color: $color-accent;
    gap: 20px;

    .details {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .label {
            font-size: 14px;
            color: green;
            font-weight: bold;
        }

        .value {
            font-size: 13px;
        }
    }
}