@use '../../../scss/mixins' as *;
@use '../../../scss/colors' as *;

.Search {
  @include onDesktop {
    height: 60px;
    max-width: 260px;
    display: flex;
    position: relative;
  }

  &.open {
    .searchInput {
      height: 41px;
      padding: 11px 26px 11px 12px;
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      border: none;
      outline: none;
      border-radius: 35px;
      background: rgb(226, 226, 226);
      color: $color-primary;
      font-size: 14px;
    }

    .searchButton {
      position: absolute;
      right: 0;
      background: transparent;
      cursor: default;
    }
  }

  .searchInput {
    display: none;

    @include onDesktop {
      width: 100%;
      padding: 16px 40px 16px 18px;
      display: block;
      border: none;
      outline: none;
      border-radius: 35px;
      background: $color-accent;
      color: $color-primary;
      font-size: 16px;
    }

    &::placeholder {
      color: $color-primary;
    }
  }

  .searchButton {
    background: $color-primary;

    @include onDesktop {
      position: absolute;
      right: 0;
      background: transparent;
      cursor: default;
    }
  }
}