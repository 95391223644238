@use '../../scss/mixins' as *;

.CreatePasswordPage {
  min-height: 100vh;

  .form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include onDesktop {
      width: 570px;
      margin-top: 64px;
      align-self: flex-start;
    }

    .title {
      margin: 0 0 36px;
      color: #161616;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 0.8px;

      @include onDesktop {
        margin-bottom: 45px;
        font-size: 26px;
        font-weight: 500;
        letter-spacing: 1.05px;
      }
    }

    .inputs {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 16px;

      @include onDesktop {
        gap: 20px;
      }

      .inputContainer {
        display: flex;
        flex-direction: column;
      }

      .passwordContainer {
        display: flex;
        flex-direction: column;
        position: relative;

        .passwordVisibilityToggle {
          padding: 0;
          display: flex;
          position: absolute;
          top: 50%;
          right: 16px;
          transform: translateY(-50%);
          border: none;
          background: transparent;
          cursor: pointer;

          @include onDesktop {
            right: 36px;
          }
        }
      }
    }

    .forgotPassword {
      align-self: flex-end;
      margin-bottom: 38px;
      color: #4F4E4E;
      font-size: 15px;
      font-weight: 600;

      @include onDesktop {
        font-size: 16px;
      }
    }

    .redirect {
      display: none;
      text-align: center;
      color: #4F4E4E;
      font-size: 16px;
      font-weight: 600;

      @include onDesktop {
        display: flex;
        justify-content: center;
        gap: 8px;
      }
    }
  }
}