@use '../../scss/mixins' as *;
@use '../../scss/colors' as *;

.PaymentsPage {
  min-height: 100vh;

  .title {
    margin: 0 0 20px;
    color: $color-primary;
    font-size: 25px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 1px;

    @include onDesktop {
      display: none;
    }
  }

  .innerContainer {
    display: flex;
    flex-direction: column;

    @include onDesktop {
      flex-direction: row;
      justify-content: space-between;
      gap: 40px;
    }

    .noBillingHistory {
      margin: 0 0 32px;
      color: #161616;
      font-size: 15px;
      font-weight: 300;
      line-height: 22px;
      letter-spacing: 0.3px;

      @include onDesktop {
        font-size: 16px;
        font-weight: 400;
        line-height: normal;
      }
    }

    .billingHistoryContainer {
      margin-bottom: 20px;
      display: flex;
      flex-direction: column;
      gap: 20px;
      order: 1;

      @include onDesktop {
        margin-bottom: 40px;
        width: 1px;
        flex-grow: 1;
        order: 0;
      }

      .billingHistoryHeader {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 15px;

        @include onDesktop {
          flex-direction: unset;
        }

        .profileButtonsContainer {
          display: flex;
          gap: 5px;

          .profileButton {
            height: unset;
            width: 50%;
            padding: 10px 30px 10px 10px;
            font-size: 13px;

            &::after {
              width: 22px;
              height: 22px;
              transform: translate(80%, -50%) rotate(-90deg);

              @include onDesktop {
                width: 30px;
                height: 30px;
                transform: translate(50%, -50%) rotate(-90deg);
              }
            }

            @include onDesktop {
              font-size: 14px;
              width: 200px;
              height: 60px;
              font-size: unset;
            }
          }
        }
      }
    }

    .cardContainer {
      margin: 0 auto 20px;
      display: flex;

      @include onDesktop {
        min-width: 350px;
        width: 350px;
        margin: 0;
      }
    }

    .paymentInformation {
      font-weight: bold;
      margin: 0 10px;
      font-size: 14px;

      @include onDesktop {
        font-size: 16px;
      }
    }
  }
}