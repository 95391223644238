@use '../../scss/mixins' as *;
@use '../../scss/colors' as *;

.ChatPage {
    .mainContent {
        padding: 0;
    }
    .chatContainer {
        display: flex;
        overflow: hidden;
        height: calc(100vh - 175px);
        max-height: calc(100vh - 175px);

        @include onDesktop {
            height: calc(100vh - 60px);
            max-height: calc(100vh - 60px);
        }


        .inbox {
            flex-grow: 1;
        }

        .noConversation {
            display: flex;
            width: 100%;
            height: 100%;
            align-items: center;
            justify-content: center;
            background-color: #fff;
            border: 1px solid #ccc;

            p {
                margin: 0;
                padding: 10px 15px;
                border-radius: 4px;
                color: #888;
                font-style: italic;
                font-size: 16px;
            }
        }
    }

    header {
        display: none !important;
    }
}
