@use '../../../../scss/mixins' as *;
@use '../../../../scss/colors' as *;

.CustomerOption {
  width: 100%;
  padding-right: 15px;
  display: flex;
  align-items: center;
  border-radius: 55px;
  background: #F6F6F6;
  cursor: pointer;

  @include onDesktop {
    padding-right: 30px;
    border-radius: 55px;
    background: #F6F6F6;
  }

  &.selected {
    padding-right: 45px;
    background: $color-background;

    @include onDesktop {
      padding-right: 75px;
      background: $color-accent;
    }
  }

  .infoContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .name {
      text-align: left;
      color: #161616;
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0.2px;

      @include onDesktop {
        font-size: 14px;
        letter-spacing: 0.32px;
      }
    }

    .email {
      display: flex;
      align-items: center;
      color: rgba(22, 22, 22, 0.70);
      font-size: 14px;
      letter-spacing: 0.18px;

      @include onDesktop {
        font-size: 14px;
        letter-spacing: 0.32px;
      }
    }
  }
}