@use '../../../../scss/mixins' as *;
@use '../../../../scss/colors' as *;

.BillingHistoryItem {
  display: flex;
  flex-direction: row;
  gap: 5px;

  @include onDesktop {
    gap: 12px;
  }

  .paymentLog {
    height: 50px;
    padding: 0 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-grow: 1;
    border-radius: 55px;
    background: $color-accent;
    width: calc(100% - 40px);
    gap: 25px;
    padding: 15px 15px 15px 20px;

    @include onDesktop {
      height: 60px;
    }

    .title {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      @include onDesktop {
        max-width: 50%;
      }
    }

    .title, .name, .createdAt {
      color: #161616;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0.2px;
      text-wrap: nowrap;

      @include onDesktop {
        font-size: 16px;
      }
    }

    .amount {
      display: flex;
      flex-direction: column;
      gap: 2px;
      font-size: 11px;
      letter-spacing: 0.18px;
      font-weight: bold;
      color: #ffffff;

      @include onDesktop {
        font-size: 13px;
      }

      .mainAmount, .refundedAmount {
        border-radius: 20px;
        padding: 2px 5px;
      }
    }

    .downloadInvoiceButton {
      display: flex;
      min-width: 25px;
      min-height: 25px;
      width: 25px;
      height: 25px;
      background: $color-primary;

      @include onDesktop {
        min-width: 40px;
        min-height: 40px;
        width: 40px;
        height: 40px;
        img {
          transform: scale(1.5);
        }
      }
    }
  }

  .openEngagementButton {
    display: flex;
    min-width: 40px;
    min-height: 40px;
    width: 40px;
    height: 40px;
    img {
      transform: rotate(90deg);
    }

    @include onDesktop {
      min-width: 60px;
      min-height: 60px;
      width: 60px;
      height: 60px;
      img {
        transform: rotate(90deg) scale(1.5);
      }
    }
  }
}