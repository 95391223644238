@use '../../../../scss/mixins' as *;
@use '../../../../scss/colors' as *;

.DropdownOption {
  width: 100%;
  padding-right: 15px;
  display: flex;
  align-items: center;
  border-radius: 55px;
  background: #F6F6F6;
  cursor: pointer;

  @include onDesktop {
    padding-right: 30px;
    border-radius: 55px;
  }

  &.selected {
    padding-left: 15px;
    background-color: #FFFFFF;
    border-radius: 0;
    width: auto;
    padding-right: 0;

    @include onDesktop {
      background: transparent;
    }

    &:last-child {
      padding-right: 45px;
      @include onDesktop {
        padding-right: 75px;
      }
    }

    .infoContainer {
      padding: 0;
    }
  }

  .infoContainer {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 5px;

    @include onDesktop {
      padding: 10px 15px;
    }

    .avatar {
      min-width: 30px;
      max-width: 30px;
      height: 30px;
    }

    .label {
      text-align: left;
      color: #161616;
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0.2px;

      @include onDesktop {
        font-size: 16px;
        letter-spacing: 0.32px;
      }
    }
  }
}