@use '../../../../scss/mixins' as *;
@use '../../../../scss/colors' as *;

.Status {
  z-index: 3;

  .statusButton {
    padding: 0;
    display: flex;
    align-items: center;
    gap: 10px;
    border: none;
    background: transparent;
  }

  .mark {
    height: 20px;
    width: 20px;
    border-radius: 50%;

    @include onDesktop {
      height: 32px;
      width: 32px;
    }
  }

  .status {
    color: #161616;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.2px;
    text-transform: capitalize;
    white-space: nowrap;

    @include onDesktop {
      font-size: 14px;
      letter-spacing: 0.44px;
    }
  }

  .statusList {
    display: none;
  }

  &.edit {
    .statusButton {
      padding: 10px 30px 10px 8px;
      position: relative;
      border-radius: 55px;
      background: $color-background;
      overflow: hidden;
      cursor: pointer;

      @include onDesktop {
        padding: 14px 44px 14px 24px;
        border-radius: 35px;
      }

      &::after {
        content: '';
        height: 36px;
        width: 36px;
        position: absolute;
        top: 0;
        right: 0;
        background-image: url('../../../../assets/icons/arrow-blue.svg');
        background-repeat: no-repeat;
        background-position: center;
        transform: rotate(180deg);

        @include onDesktop {
          height: 60px;
          width: 60px;
          transform: rotate(180deg) scale(1.5);
        }
      }
    }

    &.open {
      position: relative;

      .statusButton {
        border-radius: 25px 25px 0 0;

        @include onDesktop {
          border-radius: 35px 35px 0 0;
        }

        &::after {
          transform: unset;

          @include onDesktop {
            transform: scale(1.5);
          }
        }
      }

      .statusList {
        width: 100%;
        margin: 0;
        padding: 0 8px 8px;
        display: flex;
        gap: 8px;
        flex-direction: column;
        position: absolute;
        border-radius: 0 0 25px 25px;
        background: $color-background;
        list-style: none;
        z-index: 2;

        @include onDesktop {
          padding: 0 24px 12px;
          gap: 12px;
        }

        .statusItem {
          display: flex;
          align-items: center;
          gap: 10px;
          cursor: pointer;
        }
      }
    }
  }
}
