@use '../../../scss/mixins' as *;
@use '../../../scss/colors' as *;

.UsersItem {
  display: flex;
  gap: 16px;

  .user {
    width: 100%;
    padding-right: 16px;
    display: flex;
    align-items: center;
    border-radius: 55px;
    background: $color-accent;

    @include onDesktop {
      padding-right: 30px;
    }

    &.noChargeExists, &.requiredOnboarding {
      img {
        border: 2px solid red;
      }
    }

    .userAvatar {
      height: 40px;
      width: 40px;
      margin-right: 12px;
      border-radius: 50%;
      object-fit: cover;

      @include onDesktop {
        height: 70px;
        width: 70px;
        margin-right: 18px;
      }
    }

    .userInfo {
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 10px;
      overflow: auto;

      @include onDesktop {
        gap: 20px;
      }

      .userName {
        flex: 1;
        color: #161616;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 0.2px;

        @include onDesktop {
          font-size: 16px;
          letter-spacing: 0.44px;
        }
      }

      .userRole, .note {
        flex: 1;
        color: rgba(22, 22, 22, 0.70);
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 0.18px;

        @include onDesktop {
          font-size: 14px;
          letter-spacing: 0.36px;
        }
      }

      .userEmail {
        flex: 1;
        display: flex;
        justify-content: flex-end;
        color: rgba(22, 22, 22, 0.70);
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 0.18px;

        @include onDesktop {
          font-size: 14px;
          letter-spacing: 0.36px;
        }
      }
    }
  }

  .actions {
    display: none;

    @include onDesktop {
      display: flex;
      gap: 16px;

      .icon {
        background: $color-accent;
      }
    }
  }
}
