@use '../../scss/mixins' as *;
@use '../../scss/colors' as *;

.DedicatedPmsList {
  display: flex;
  flex-direction: column;
  gap: 20px;
  .user {
    width: 100%;
    padding: 1rem;

    display: flex;
    justify-content: start;
    align-items: center;
    border-radius: 25px;
    background: $color-accent;

    @include onDesktop {
      padding-right: 30px;
    }

    &.noChargeExists, &.requiredOnboarding {
      img {
        border: 2px solid red;
      }
    }

    .userAvatar {
      height: 40px;
      width: 40px;
      margin-right: 1px;
      border-radius: 50%;
      object-fit: cover;

      @include onDesktop {
        height: 130px ;
        width: 130px !important ;
        margin-right: 18px;
      }
    }

    .userInfo {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 20px;
      margin-left: 15px;

      @include onDesktop {
        gap: 60px;
        margin-left: 25px
      }

      .userName {
        flex: 1;
        color: #161616;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 0.2px;

        @include onDesktop {
          font-size: 20px;
          letter-spacing: 0.44px;
        }
      }

      .userRole, .note {
        flex: 1;
        color: rgba(22, 22, 22, 0.70);
        font-size: 10px;
        font-weight: 400;
        letter-spacing: 0.18px;

        @include onDesktop {
          font-size: 12px;
          letter-spacing: 0.36px;
        }
      }

      .userEmail {
        flex: 1;
        display: flex;
        justify-content: flex-end;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 0.18px;

        @include onDesktop {
          font-size: 16px;
          letter-spacing: 0.36px;
        }
      }

      .LinkButton {
        color: $color-primary;
        text-decoration: none;
        font-size: 14px;
        margin-bottom: 2px;
      }
    }
  }

  .actions {
    display: none;

    @include onDesktop {
      display: flex;
      gap: 16px;

      .icon {
        background: $color-accent;
      }
    }
  }
}
