@use '../../../../scss/mixins' as *;
@use '../../../../scss/colors' as *;

.GroupButton {
  display: flex;
  justify-content: center;

  & > button {
    padding: 3px 7px;
    border: none;
    cursor: pointer;
    font-size: 13px;
    text-wrap: nowrap;

    &:hover {
      text-decoration: underline;
    }

    &:first-child {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }

    &:last-child {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }

    &.primary {
      background-color: $color-primary;
      color: #f7f7f7;
    }
    &.success {
      background-color: lightgreen;
    }
    &.warning {
      background-color: lightgoldenrodyellow;
    }
    &.danger {
      background-color: lightcoral;
    }

    &.primary.outline {
      color: $color-primary;
    }
    &.success.outline {
      color: lightgreen;
    }
    &.warning.outline {
      color: orange;
    }
    &.danger.outline {
      color: lightcoral;
    }

    &.outline {
      border-style: dashed;
      background: transparent;
    }

    &.primary.transparent {
      color: $color-primary;
    }
    &.success.transparent {
      color: lightgreen;
    }
    &.warning.transparent {
      color: orange;
    }
    &.danger.transparent {
      color: lightcoral;
    }

    &.transparent {
      padding: 2px 5px;
      border: none;
      background: transparent;
    }

    &.outline:not(:last-child) {
      border-right: none;
    }

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
}
