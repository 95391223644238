@use '../../scss/mixins' as *;
@use '../../scss/colors' as *;

.contentContainer {
  display: flex;
  flex-direction: column;

  & > div:first-child {
    border-top-left-radius: 35px;
    border-top-right-radius: 35px;
    border: none;
    border-bottom: 1px solid #ccc;
  }
  & > div:last-child {
    border-bottom-left-radius: 35px;
    border-bottom-right-radius: 35px;
    border: none;
    overflow: auto;
  }

  &.error {
    background: rgba(219, 40, 40, 0.14) !important;
  }
}