@mixin onTablet {
  @media (min-width: 768px) and (max-width: 1200px) {
    @content;
  }
}
@mixin onDesktop {
  @media (min-width: 1200px) {
    @content;
  }
}
