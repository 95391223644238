@use '../../../../scss/mixins' as *;

.LogList {
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  list-style: none;

  @include onDesktop {
    gap: 30px;
  }
}
