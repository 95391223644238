@use '../../../scss/mixins' as *;

.Tooltip {
  position: relative;

  .visibleTooltip {
    width: 144px;
    padding: 8px;
    position: absolute;
    bottom: 15px;
    left: -25px;
    z-index: 2;
    border-radius: 15px;
    background: #D9D9D9;
    white-space: normal;
    word-wrap: break-word;
    color: rgba(22, 22, 22, 0.55);
    font-size: 4px;
    line-height: 7px;

    @include onDesktop {
      width: 422px;
      padding: 12px 24px;
      bottom: 25px;
      left: 0;
      border-radius: 35px;
      font-size: 14px;
      line-height: 15px;
    }

    .note {
      font-weight: 700;
    }
  }
}