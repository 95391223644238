@use '../../../../../scss/mixins' as *;
@use '../../../../../scss/colors' as *;

.PublicProfileLinkItem {
  display: flex;
  flex-direction: row;
  gap: 5px;

  @include onDesktop {
    gap: 12px;
  }

  .profileInfoContainer {
    height: 40px;
    padding: 0 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 55px;
    background: $color-accent;
    width: calc(100% - 40px);
    gap: 25px;
    padding: 15px 15px 15px 20px;

    @include onDesktop {
      height: 60px;
    }

    .link {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: #161616;
      font-size: 14px;
      letter-spacing: 0.2px;

      @include onDesktop {
        max-width: 300px;
        font-size: 16px;
      }
    }

    .expiresAt {
      font-size: 14px;
      letter-spacing: 0.18px;
      font-weight: 500;

      @include onDesktop {
        font-size: 16px;
      }
    }
  }

  .deleteButton {
    display: flex;
    min-width: 40px;
    min-height: 40px;
    width: 40px;
    height: 40px;

    @include onDesktop {
      min-width: 60px;
      min-height: 60px;
      width: 60px;
      height: 60px;
      img {
        transform: scale(1.5);
      }
    }
  }
}