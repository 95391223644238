@use '../../scss/mixins' as *;
@use '../../scss/colors' as *;

.dialog {
  max-width: 425px;
  width: 100%;
  position: fixed;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  z-index: 100;

  @include onDesktop {
    max-width: 600px;
    right: calc(50% - 300px / 2);
  }
}

.HoursCounterModal {
  background: #e3e3e3;
  border-radius: 5px;
  overflow: hidden;
  -webkit-box-shadow: 0 0 2px rgba(0,0,0,0.15);
  -moz-box-shadow: 0 0 2px rgba(0,0,0,0.15);
  -o-box-shadow: 0 0 2px rgba(0,0,0,0.15);
  box-shadow: 0 0 2px rgba(0,0,0,0.15);

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;

    .section {
      width: 100%;
      padding: 20px 16px;

      @include onDesktop {
        padding: 26px 20px;
      }
    }

    .title {
      margin: 0;
      text-align: center;
      font-size: 20px;
      font-weight: bold;
      letter-spacing: 0.52px;
      padding: 13px 6px;

      @include onDesktop {
        padding: 16px 8px;
        font-size: 27px;
      }
    }

    .body {
      display: flex;
      flex-direction: column;
      gap: 12px;
      background: #ccc;

      @include onDesktop {
        gap: 14px;
      }

      .description {
        font-weight: bold;
        margin-top: 0;
        text-align: center;
        font-size: 14px;

        @include onDesktop {
          font-size: 14px;
        }
      }

      .counters {
        display: flex;
        gap: 45px;
        justify-content: center;
      }

      .submitButton {
        border-radius: 3px;
      }
    }
  }
}
