@use '../../../../scss/mixins' as *;
@use '../../../../scss/colors' as *;

.AddHiresDropdown {
  margin: auto 0;

  @include onDesktop {
    position: absolute;
    right: 0;
    z-index: 2;
  }

  &.open {
    width: 100%;

    @include onDesktop {
      width: 570px;
    }

    .dropdownToggle {
      width: 100%;
      border-radius: 16px 16px 0 0;

      @include onDesktop {
        border-radius: 35px 35px 0 0;
      }
    }
  }

  &.selected {
    .dropdownToggle {
      height: unset;
      width: 100%;
      padding: 0;
    }
  }

  .dropdownToggle {
    height: 40px;
    width: 128px;
    padding: 12px 16px;
    position: relative;
    border: none;
    border-radius: 35px;
    text-align: left;
    font-size: 14px;
    letter-spacing: 0.2px;
    background: $color-background;
    color: $color-primary;

    @include onDesktop {
      height: 60px;
      width: 180px;
      padding: 18px 22px;
      display: flex;
      align-items: center;
      font-size: 14px;
      letter-spacing: 0.36px;
    }

    .button {
      position: absolute;
      top: 0;
      right: 0;
      background: $color-primary;
    }

    .searchInput {
      background: $color-background;
    }
  }

  .list {
    position: absolute;
    z-index: 4;
    background: $color-background;
  }

  .dropdownItem {
    background: rgba(234, 233, 233, 0.36);
  }
}