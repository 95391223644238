@use '../../../scss/mixins' as *;
@use '../../../scss/colors' as *;

.Textarea {
  height: 260px;
  padding: 14px 20px;
  border-radius: 20px;
  background: $color-background;

  @include onDesktop {
    height: 300px;
    padding: 26px 30px;
    border-radius: 40px;
    background: $color-accent;
  }

  textarea {
    height: 100%;
    width: 100%;
    border: none;
    outline: none;
    resize: none;
    color: #161616;
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 0.18px;
    background: transparent;

    @include onDesktop {
      font-size: 14px;
      font-weight: 400;
    }
  }

  &.error {
    background: rgba(219, 40, 40, 0.14);
  }
}