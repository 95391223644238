@use '../../../scss/mixins' as *;
@use '../../../scss/colors' as *;

.Checkbox {
  &.white .checkmark {
    background-image: url('../../../assets/icons/checkbox-white-unchecked.svg');
  }

  &.white .container input:checked~.checkmark {
    background-image: url('../../../assets/icons/checkbox-white-checked.svg');
  }

  .container {
    padding-left: 25px;
    display: flex;
    position: relative;
    -webkit-user-select: none;
    user-select: none;
    white-space: nowrap;
    cursor: pointer;

    @include onDesktop {
      padding-left: 32px;
    }

    .label {
      color: #4F4E4E;
      font-size: 14px;

      @include onDesktop {
        font-size: 16px;
      }
    }

    &.disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }

  .container input {
    height: 0;
    width: 0;
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  .checkmark {
    height: 18px;
    width: 18px;
    position: absolute;
    top: calc(50% - 9px);
    left: 0;
    background-image: url('../../../assets/icons/checkbox-unchecked.svg');
    background-repeat: no-repeat;
    background-position: center;

    @include onDesktop {
      height: 32px;
      width: 32px;
      top: calc(50% - 16px);
    }
  }

  .container input:checked~.checkmark {
    background-image: url('../../../assets/icons/checkbox-checked.svg');

    &.disabled {
      background: $color-background;
      cursor: not-allowed;
    }
  }
}
