@use '../../scss/mixins' as *;

.PublicProfilePage {
  margin: 15px;

  @include onTablet {
    margin: 20px 120px;
  }

  @media (min-width: 1200px) and (max-width: 1500px) {
    margin: 40px 300px;
  }

  @media (min-width: 1500px) {
    margin: 40px 500px;
  }
}