@use '../../../scss/mixins' as *;

.ArchiveCheckbox {
  display: flex;
  flex-direction: column;
  gap: 15px;

  @include onDesktop {
    max-width: 294px;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 22px;
  }

  & > div {
    background-color: aliceblue;
    padding: 8px;
    border-radius: 24px;
  }

  &.archived > div {
    background-color: lightcoral;
  }
}