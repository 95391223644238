@use '../../scss/mixins' as *;
@use '../../scss/colors' as *;

.Toolbar {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
  align-items: end;

  @include onDesktop {
    margin-bottom: 30px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .title {
    width: 100%;
    margin: 0;
    color: $color-primary;
    font-size: 25px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 1px;

    @include onDesktop {
      display: none;
    }
  }

  .actions {
    display: flex;
    justify-content: end;
    gap: 6px;
    width: 100%;
    position: relative;

    @include onDesktop {
      justify-content: space-between;
    }

    .buttons {
      display: flex;
      flex-direction: row-reverse;
      gap: 6px;

      @include onDesktop {
        flex-direction: row;
      }

      &.grow {
        @include onDesktop {
          flex-grow: 1;
        }
      }

    }

    .createButton {
      width: unset;
      position: fixed;
      left: 20px;
      right: 20px;
      bottom: 40px;
      color: #F6F6F6;
      font-size: 15px;
      font-weight: 500;
      z-index: 4;

      @include onDesktop {
        min-width: 170px;
        padding: 0 60px 0 16px;
        position: relative;
        left: 0;
        right: 0;
        bottom: 0;
        text-align: left;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 0.36px;
      }
    }
  }
}