@use '../../../scss/mixins' as *;

.Tr {
  & > td {
    height: 50px;
    min-width: 100px;
    max-width: 200px;
    padding: 10px;
  }
}
