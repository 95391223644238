@use '../../../../../../scss/mixins' as *;
@use '../../../../../../scss/colors' as *;

.Rate {
  display: flex;
  flex-direction: column;
  justify-content: center;

  .title {
    display: flex;
    gap: 4px;
    color: #161616;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    letter-spacing: 0.18px;

    @include onDesktop {
      font-size: 12px;
      letter-spacing: 0.32px;
    }

    .infoIcon {
      @include onDesktop {
        display: none;
      }
    }
  }

  .content {
    color: #161616;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.18px;

    @include onDesktop {
      display: flex;
      gap: 14px;
      letter-spacing: 0.32px;
    }

    .infoIcon {
      display: none;

      @include onDesktop {
        display: inline-flex;
      }
    }
  }

  .inputContainer {
    position: relative;

    &::after {
      content: var(--title);
      position: absolute;
      top: 8px;
      left: 20px;
      color: #161616;
      font-size: 6px;
      font-weight: 300;
      letter-spacing: 0.32px;

      @include onDesktop {
        font-size: 14px;
      }
    }

    .input {
      height: 40px;
      width: 100%;
      background-color: $color-accent;
      font-size: 14px;

      @include onDesktop {
        height: 60px;
        font-size: 14px;
      }
    }
  }
}