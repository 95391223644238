@use '../../scss/mixins' as *;
@use '../../scss/colors' as *;

.dialog {
  max-width: 425px;
  width: 100%;
  position: fixed;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  z-index: 100;

  @include onDesktop {
    max-width: 600px;
    right: calc(50% - 300px / 2);
  }
}

.DirtyPaymentLogModal {
  background: #e3e3e3;
  border-radius: 5px;
  overflow: hidden;
  -webkit-box-shadow: 0 0 2px rgba(0,0,0,0.15);
  -moz-box-shadow: 0 0 2px rgba(0,0,0,0.15);
  -o-box-shadow: 0 0 2px rgba(0,0,0,0.15);
  box-shadow: 0 0 2px rgba(0,0,0,0.15);

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;

    .section {
      width: 100%;
      padding: 20px 16px;

      @include onDesktop {
        padding: 26px 20px;
      }
    }

    .title {
      margin: 0;
      text-align: center;
      font-size: 20px;
      font-weight: bold;
      letter-spacing: 0.52px;
      padding: 13px 6px;

      @include onDesktop {
        padding: 16px 8px;
        font-size: 27px;
      }
    }

    .body {
      display: flex;
      flex-direction: column;
      gap: 12px;
      background: #ccc;

      @include onDesktop {
        gap: 14px;
      }

      .inputContainer {
        width: 100%;
        display: flex;
        flex-direction: column;

        .input {
          border-radius: 20px;
          background: $color-background;
          color: #161616;
          font-size: 14px;
          font-weight: 300;
          letter-spacing: 0.26px;

          @include onDesktop {
            height: 45px;
            border-radius: 40px;
            background: $color-accent;
            font-size: 16px;
            font-weight: 400;
            letter-spacing: 0.4px;
          }
        }
      }

      .submitButton {
        height: 45px;
      }
    }
  }
}
