@use '../../scss/mixins' as *;
@use '../../scss/colors' as *;

.ChatManagementTable {
  margin-top: 10px;

  .tableContainer {
    max-width: 100vw;
    overflow-x: auto;
    border-radius: 24px;
    box-shadow: 0 0 3px 0 #ccc;

    @include onDesktop {
      max-width: calc(100vw - 450px);
    }

    table {
      width: 100%;

      .empty {
        height: 40px;
      }

      tbody {
        border-top-color: #E7E7E7;

        tr:nth-child(even) {
          background-color: #FFF;
        }
        tr:nth-child(odd) {
          background-color: #F4F4F4;
        }
      }

      th {
        background-color: #f7f7f7;

        span {
          width: unset;
          height: unset;
          padding: 0;
        }
      }

      .members > div {
        display: flex;
        flex-wrap: wrap;
        gap: 5px;

        span {
          padding: 3px 7px;
          font-size: 12px;
          font-weight: 500;
          border-radius: 5px;
          border: 1px dashed cyan;
          background-color: lightcyan;
        }
      }

      .readonly > div {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .thactions > div {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

  }
}