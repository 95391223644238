@use '../../scss/mixins' as *;

.gettingStartedPage {
  min-height: 100vh;
  padding-bottom: 72px;

  @include onDesktop {
    padding-bottom: 0;
  }

  .content {
    font-size: 14px;
  }

  .mainIntro {
    display: flex;
    flex-direction: column;
    flex-flow: column-reverse;
    gap: 12px;
    margin-top: 30px;

    @include onDesktop {
      flex-direction: row;
      align-items: center;
    }

    & > div {
      @include onDesktop {
        width: 50%;
      }
    }

    .contentContainer {
      padding: 0;
      margin: 0;
      background-color: transparent;
      z-index: 7;

      iframe {
        width: 100%;
        min-height: 300px;
      }

      & > button:nth-child(2) {
        position: relative;
        bottom: unset;
        margin-top: 20px;
      }
    }
  }
}
