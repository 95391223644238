@use '../../../../../scss/mixins' as *;
@use '../../../../../scss/colors' as *;

.LogItem {
  padding: 16px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
  border-radius: 25px;
  background: $color-accent;

  @include onDesktop {
    width: calc(50% - 15px);
    padding: 30px;
  }

  &.oddLast {
    @include onDesktop {
      width: 100%;
    }
  }

  .logInfo {
    display: flex;
    gap: 5px;
    font-size: 14px;
    color: #555;
    overflow-wrap: anywhere;
    word-break: break-all;

    & > span:first-child {
      white-space: nowrap;
      font-weight: bold;
      min-width: 130px;
    }
  }

  .buttonContainer {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-top: 20px;

    .updateButton, .deleteButton {
      height: 45px;
      font-size: 16px;
    }

    .deleteButton {
      background-color: lightcoral;
    }
  }

}