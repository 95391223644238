@use '../../../scss/mixins' as *;
@use '../../../scss/colors' as *;

.AvatarUpload {
  height: 156px;
  width: 156px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 50%;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTU2IiBoZWlnaHQ9IjE1NiIgdmlld0JveD0iMCAwIDE1NiAxNTYiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxjaXJjbGUgY3g9Ijc4IiBjeT0iNzgiIHI9Ijc3LjUiIHN0cm9rZT0iIzRGNEU0RSIgc3Ryb2tlLWRhc2hhcnJheT0iMjAgMjAiLz4KPC9zdmc+Cg==");

  @include onDesktop {
    height: 216px;
    width: 216px;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMTYiIGhlaWdodD0iMjE2IiB2aWV3Qm94PSIwIDAgMjE2IDIxNiIgZmlsbD0ibm9uZSI+CjxjaXJjbGUgY3g9IjEwNy42NDgiIGN5PSIxMDguMDEzIiByPSIxMDcuMjM1IiBzdHJva2U9IiM0RjRFNEUiIHN0cm9rZS13aWR0aD0iMC43NTI1MjciIHN0cm9rZS1kYXNoYXJyYXk9IjE1LjA1IDE1LjA1Ii8+Cjwvc3ZnPg==");
  }

  .uploadedContent {
    height: 22px;
    width: 24px;

    @include onDesktop {
      height: 40px;
      width: 46px;
    }

    &.avatar {
      height: 100%;
      width: 100%;
      border-radius: 50%;
      object-fit: cover;
    }
  }

  .uploadButton, .deleteButton {
    position: absolute;
    right: 0;
    bottom: 0;
    background: $color-primary;
  }

  .deleteButton {
    left: 0;
    background: $color-background;
    border: 2px solid $color-danger;
  }
}