@use '../../../scss/mixins' as *;
@use '../../../scss/colors' as *;

.Filter {
  position: relative;

  @include onDesktop {
    display: flex;
    order: 2;
    border-radius: 35px;
    background: $color-accent;
  }

  &.open {
    padding: 0 20px;

    .filterButton {
      position: absolute;
      right: 0;
      background: #F6F6F6;
      z-index: 5;

      @include onDesktop {
        position: unset;
      }
    }

    @include onDesktop {
      .filterButton {
        background: unset;

        img {
          transform: rotate(180deg) scale(1.5);
        }
      }
    }

    .filterOptionsContainer {
      display: flex;
    }
  }

  .filterButton {
    background: $color-primary;

    @include onDesktop {
      width: 150px;
      position: relative;
      border-radius: 35px;
      background: $color-accent;

      &::before {
        content: 'Show all';
        color: #4F4E4E;
        font-size: 16px;
        font-weight: 600;
        text-decoration-line: underline;
      }
    }

    img {
      height: 16px;
      width: 16px;

      @include onDesktop {
        margin-left: 18px;
      }
    }
  }

  .filterOptionsContainer {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 4;

    @include onDesktop {
      position: unset;
    }

    .checkboxes {
      padding: 18px 44px 18px 30px;
      display: flex;
      flex-direction: column;
      gap: 15px;
      background: $color-primary;
      border-radius: 20px;

      @include onDesktop {
        margin-right: 22px;
        padding: 5px;
        gap: 12px;
        flex-direction: row;
        align-items: center;
        background: transparent;
        flex-wrap: wrap;
      }

      .label {
        color: #F6F6F6;
        font-size: 14px;

        @include onDesktop {
          color: #4F4E4E;
          font-size: 14px;
        }
      }
    }

    .applyButton {
      background-color: $color-primary;
    }
  }
}