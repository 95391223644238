@use '../../../scss/mixins' as *;

.Th {
  position: sticky;
  top: 0;
  white-space: nowrap;
  height: 50px;
  padding: 0 10px;
  text-align: left;
  background-color: #FFF;

  &.sortable {
    cursor: pointer;
  }

  &.sortable {
    cursor: pointer;
  }

  & > div {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & > span:last-child {
      position: relative;
      width: 15px;
      height: 15px;
      padding: 0 15px;

      & > span {
        display: flex;
        align-items: center;
        position: absolute;
      }

    }
  }

  .sortNone {
    color: #ccc;
  }
}
