@use '../../../../../../scss/mixins' as *;

.EditableStatus {
  display: flex;
  flex-direction: column;
  justify-content: center;

  .title {
    color: #161616;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    letter-spacing: 0.18px;

    @include onDesktop {
      font-size: 12px;
      letter-spacing: 0.32px;
    }
  }

  .content {
    color: #161616;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.18px;
    white-space: nowrap;

    @include onDesktop {
      letter-spacing: 0.32px;
    }
  }
}