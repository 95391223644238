@use '../../../scss/mixins' as *;
@use '../../../scss/colors' as *;

.PhoneNumberInput {
  background: $color-accent;
  padding-left: 20px;
  border-radius: 55px;

  @include onDesktop {
    padding-left: 26px;
  }

  input, input:focus, input:active {
    height: 45px;
    padding: 14px 20px 14px 6px;
    border: none;
    border-radius: 55px;
    background: transparent;
    color: #4f4e4e;
    font-size: 15px;
    font-weight: 400;
    outline: none;

    @include onDesktop {
      height: 60px;
      padding: 16px 26px 16px 8px;
      font-size: 16px;
    }
  }

  &.error {
    background: rgba(219, 40, 40, 0.14) !important;
  }

  &.disabled {
    background: #eeeeee !important;
  }
}
