@use '../../../scss/mixins' as *;

.ErrorMessage {
  margin-top: 6px;
  color: rgba(219, 40, 40, 0.63);
  font-size: 15px;
  font-weight: 400;

  @include onDesktop {
    margin-top: 14px;
    margin-left: 7px;
    font-size: 14px;
  }
}
